import { render, staticRenderFns } from "./addDocument.vue?vue&type=template&id=7c7d0fca&scoped=true&"
import script from "./addDocument.vue?vue&type=script&lang=ts&"
export * from "./addDocument.vue?vue&type=script&lang=ts&"
import style0 from "./addDocument.vue?vue&type=style&index=0&id=7c7d0fca&lang=scss&scoped=true&"
import style1 from "./addDocument.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7d0fca",
  null
  
)

export default component.exports